



















































import Vue from 'vue'
import Component from 'vue-class-component'
import { apiUserLists } from '@/api/user'
@Component
export default class UserList extends Vue {
	dialogVisible :boolean = false
	// 搜索表单数据
	form = {
		disable: '',
		create_start_time:0,
		create_end_time:0,
		keyword:'',
		page_no:1,
		page_size:10
	};
	// 数据表格
	tableData = [];
	registerTime :any = null
	// 模拟用户状态选项
	userStatuses = [
		{ value: 0, label: '正常' },
		{ value: 1, label: '禁用' }
	];

	// 分页总数
	total = 100;
	userItme = []
	handleSelectionChange(row:any){
		this.userItme = row
	}
	submint(){
		if(this.userItme.length > 1) return this.$message.error('只能选择一个用户')
		this.$emit('change',this.userItme[0])
		this.dialogVisible = false
	}
	changeTime(){
		if(this.registerTime){
			this.form.create_start_time = new Date(this.registerTime[0]).getTime() / 1000
			this.form.create_end_time = new Date(this.registerTime[1]).getTime() / 1000
		}
	}
	// 查询操作
	onSearch() {
		this.getUserLists()
	}
	// 重置操作
	onReset() {
		this.registerTime = null
		this.form = {
			disable: '',
			create_start_time:0,
			create_end_time:0,
			keyword:'',
			page_no:1,
			page_size:10
		}
		this.getUserLists()
	}

	// 刷新操作
	onRefresh() {
		this.$router.go(0)
	}
	// 查看详情
	viewDetails(row : any) {
		console.log('查看详情:', row)
	}

	// 分页切换
	handlePageChange(page : number) {
		this.form.page_no = page
		this.getUserLists()
	}
	getUserLists(){
		apiUserLists(this.form).then((res)=>{
			this.tableData = res.lists
			this.total = res.count
		})
	}
	
}
